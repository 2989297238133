import { Button, Col, Row, Space, Table, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Highlight } from "./Highlight";
import scrollIntoView from 'scroll-into-view';

const Text = ({
  dataModel,
  dataScenario,
  selectModelText,
  setSelectModelText,
  selectTags,
  setSelectTags,
  focusRow,
  setFocusRow
}) => {
  const columns = [
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      width: "20%",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      align: "center",
      width: "20%",
    },
    {
      title: <Row justify="end"></Row>,
      dataIndex: "text",
      key: "text",
      render: (_, row) => {
        let textHighlight = [];
        let tagHightlight = selectTags ? selectTags : dataModelText[0]?.model_name

        row.model_name
          .filter((item) => {
            if (tagHightlight == "") {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d) => {
                  return d;
                });
                return res;
              }
            } else if (item.name == tagHightlight) {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d) => {
                  return d;
                });
                return res;
              }
            }
          })
          .map((d) => {
            const res = d.highlight.map((item) => {
              dataModelText.map((item_text) => {
                if (item_text.model_name == d.name) {
                  textHighlight.push(item);
                  return item;
                } else {
                  return item;
                }
              })
            });
            return res;
          });

        return (
          <Highlight
            tags={textHighlight.sort(
              (a, b) => a.being_char - b.being_char
            )}
          >
            {row.text}
          </Highlight>
        );
      },
    },
  ];

  const [arrText, setArrText] = useState([])
  let myVideo = document.getElementById("video");


  const SecToTime = (timeInSeconds) => {
    console.log("timeInSeconds", timeInSeconds)
    var date = new Date(0);
    date.setSeconds(timeInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString
  };

  const dataModelText = dataModel.filter(
    (i) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
  );
  let alldata = [];
  dataScenario[0]?.text_subtitle.map((i) => {
    i.model_name.map((d) => {
      alldata.push(d);
    });
  });

  const tagObject = alldata.reduce((group, product) => {
    // console.log(" product.highlight.length", product)
    const { name } = product;
    group[name] = group[name] ?? [];
    group[name].push({ value: product.highlight.length });
    return group;
  }, {});

  const calculateSum = (array, property) => {
    if (array != undefined) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);

      return total;
    }

  };

  dataModelText.map((d) => {
    if (tagObject[d.model_name] != undefined) {
      return { name: d.text, value: calculateSum(tagObject[d.model_name], "value") }
    } else {
      return null
    }
  }).filter((item) => item != null)

  dataModelText?.map((d) => {
    const lengthText = calculateSum(tagObject[d.model_name], "value")

    if (lengthText == 0) {

    } else if (lengthText > 0 && selectModelText.length == 0) {
      setSelectModelText(d.model_name)
    }
  })

  useEffect(() => {
  }, [dataScenario])

  const dataModelSet = useMemo(() => {
    let test = []
    console.log("tagObject", tagObject)
    const testmodel = dataModel && dataModel.filter(
      (i) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
    ).map((d) => {

      const lengthText = calculateSum(tagObject[d.model_name], "value")
      console.log("lengthText::::", lengthText)

      if (lengthText == 0) {

      } else if (lengthText > 0 && selectModelText.length == 0) {

        // return d.model_name
        test = d.model_name
      }
    })
    setSelectModelText(test)
    console.log("selectModelText::::testmodel", testmodel)
    return test
  }, [dataModel])

  const testResult = useMemo(() => {

    const res = dataScenario[0]?.text_subtitle.map((d, ind) => {
      return {
        id: d.sub_id,
        time: SecToTime(Number(d.start_time)),
        start_time: SecToTime(Number(d.start_time)),
        end_time: SecToTime(Number(d.end_time)),
        model_name: d.model_name,
        text: d.text,
      };
    });

    const data = res && res.map((item) => {

      const result = item.model_name.map((d) => {
        if (d.name == dataModelSet && d.highlight.length != 0) {
          return { ...d, id: item.id }
        }
      }).filter((d) => d != undefined)
      return result[0]
    }).filter((d) => d != undefined)

    setArrText(data)

    return res
  }, [dataScenario, dataModel])

  const [textHighlightSelect, setTextHighlightSelect] = useState(null)
  const backScroll = () => {
    try {
      if (textHighlightSelect == null) {
        setTextHighlightSelect(0)

        scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
          align: {
            top: 0,
          },
        });
      } else {
        if (textHighlightSelect == 0) {
          setTextHighlightSelect(0)
          scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
            align: {
              top: 0,
            },
          });
        } else {
          setTextHighlightSelect(textHighlightSelect - 1)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect - 1].id}`), {
            align: {
              top: 0,
            },
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const nextScroll = () => {
    try {
      if (textHighlightSelect == null) {
        setTextHighlightSelect(0)
        scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
          align: {
            top: 0,
          },
        });
      } else {
        if (textHighlightSelect + 1 == arrText.length) {
          setTextHighlightSelect(textHighlightSelect)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect].id}`), {
            align: {
              top: 0,
            },
          });
        } else {
          setTextHighlightSelect(textHighlightSelect + 1)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect + 1].id}`), {
            align: {
              top: 0,
            },
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  console.log('selectModelText', selectModelText)

  return (
    <Row>
      <Col xs={20}>
        <Space size={[0, 8]} wrap>
          {dataModelText.map((d) => {
            return (
              <Tag
                key={d.model_id}
                color={selectTags ? selectTags != d.model_name ? "default" : "#55acee" : dataModelText[0]?.model_name != d.model_name ? "default" : "#55acee"}
                // color={selectModelText != d.model_name ? "default" : "#55acee"}
                bordered={false}
                className={selectTags ? selectTags == d.model_name ? "video-tag-selected" : "video-tag-unselected" : dataModelText[0]?.model_name != d.model_name ? "video-tag-selected" : "video-tag-unselected"}
                // className={selectModelText.length == 0 ? "video-tag-selected" : selectModelText != d.model_name ? "video-tag-unselected" : "video-tag-selected"}
                style={{ cursor: "pointer", borderWidth: "4px" }}
                onClick={() => {
                  setSelectModelText(d.model_name);
                  setSelectTags(d.model_name);
                }}
              >
                {d.text} (
                {tagObject[d.model_name] == undefined
                  ? 0
                  : calculateSum(tagObject[d.model_name], "value")}
                )
              </Tag>
            );
          })}
        </Space>
      </Col>
      <Col sm={4}>
        <Button onClick={backScroll}>{"<"}</Button>
        <Button onClick={nextScroll}>{">"}</Button>
      </Col>
      <Col xs={24}>
        <Row>
          {Array(5).map((d) => {
            return <Col xs={6}>test</Col>;
          })}
        </Row>
      </Col>
      <Col xs={24} style={{ marginTop: "10px" }}>
        <Table
          rowClassName={(record, index) => (`scroll-${record.id}`)}
          rowKey="id"
          columns={columns}
          dataSource={testResult}
          onRow={(record, index) => {
            return ({
              style: {
                background: `.scroll-${record.id}` == focusRow ?
                  '#c7c5c5'
                  : 'white',
              },
              onClick: event => {
                let a = record.start_time.split(':')
                let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                myVideo.currentTime = seconds;
              },
            })
          }}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Col>
    </Row>
  );
};

export default Text;

import {
  InboxOutlined,
  PlusOutlined,
  SwitcherOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  ColorPicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  SelectProps,
  Space,
  Tag,
  Upload,
  Row,
  Col,
  Progress,
  // UploadProps,
  Spin,
  message,
  ColorPickerProps,
  InputRef,
  theme,
} from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { upload_videoAPI } from "../services/uploads";
import { translate } from "../utils/libs/translate";
import { Color } from "antd/es/color-picker";
import { addTag, getTagList } from "../services/task";
import type { UploadProps } from "antd";
import axios from "axios";
import { useSocket } from "../utils/socketContext/socketProvider";
import { getToken } from "../utils/prefix";
import { sendNotification } from "../services/notification";
import { useAuth } from "../utils/Auth/AuthWrapper";
import { cache } from "@amcharts/amcharts4/core";
import useNotification from "./Notification";
const { Dragger } = Upload;

type Props = {
  isModalOpen: any;
  handleCancel: any;
  validateMessages: any;
  onChange: any;
  scenarioShow: any;
  setSelectTag: any;
  selectTag: any;
  form: any;

  progressUpload: any;
  setProgressUpload: any;
  processStatus: any;
  setProgressStatus: any;

  hindUpload: boolean;
  setHindUpload: (value: any) => void;

  setIsModalOpen: (value: any) => void;
  openNotification: any;
};
interface TagItem {
  name: string;
  value: string;
  color: Color | string;
}

const UploadVideo = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [videoFile, setVideoFile] = useState<any>(null);
  const [selectScenario, setSelectScenario] = useState([]);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<TagItem[]>([]);
  const [color, setColor] = useState<Color | string>("#000");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const [formatHex, setFormatHex] = useState<ColorPickerProps["format"]>("hex");
  // const [progressUpload, setProgressUpload] = useState<any>(0);
  // const [processStatus, setProgressStatus] = useState<any>("active");
  const [files, setFiles] = useState<any>([]);

  const socket = useSocket();
  const { user } = useAuth();
  let index = 0;

  useEffect(() => {
    const getTagData = async () => {
      try {
        const res = await getTagList();
        if (res.status === 200) {
          var initialArray = res.result.map((item: any) => ({
            name: item.name,
            value: item.name,
            color: item.color,
          }));
          setItems(initialArray);
        }
      } catch (error) {
        console.log("cannot get tag data");
      }
    };

    getTagData();
  }, []);

  const addTagData = async (data: any) => {
    try {
      const res = await addTag(data);
      return res;
    } catch (error) {
      return error;
    }
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const color: any = items.filter((item) => {
      return item.value === value;
    });

    return (
      <Tag
        color={color[0]?.color}
        style={{ marginRight: 3 }}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const handleSelectOpen = (open: any) => {
    setOpen(open);
  };

  const onColorChange = (color: Color | string) => {
    setOpen(true);
    setColor(typeof color === "string" ? "string" : color.toHexString());
  };

  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    var itemName = inputRef.current?.input.value;
    try {
      if (itemName !== "") {
        const res = await addTagData({ name: itemName, color: color });
        if (res.status === 200) {
          setItems([
            ...items,
            { name: itemName, value: itemName, color: color } ||
              `New item ${index++}`,
          ]);
          inputRef.current.input.value = null;
        } else {
          messageApi.error("Duplicated Tag!");
          console.log("error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (checkedValues: any) => {
    // console.log("checked = ", checkedValues);
    setSelectScenario(checkedValues);
  };

  const propsDragger: UploadProps = {
    name: "file",
    multiple: false,
    fileList: files,
    onChange(info: any) {
      const { status } = info.file;
      props.setProgressUpload(0);
      props.setProgressStatus("active");
      // setProgressUpload(0)
      // setProgressStatus("active")
      setFiles(info.fileList);

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
        setFiles(info.fileList);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    maxCount: 1,
    beforeUpload: async (file, fileList) => {
      // setFiles(fileList);
      setVideoFile(file);
      //check file type
      const isVideo =
        file.type == "video/mp4" ||
        file.type == "video/avi" ||
        file.type == "video/mov" ||
        file.type == "video/webm" ||
        file.type == "video/flv" ||
        file.type == "audio/wav" ||
        file.type == "audio/mp3";
      console.log("isVideo", isVideo, file.type);
      //check file size
      const check_size =
        Number(file.size) >=
        Number(process.env.REACT_APP_LIMIT_VIDEOFILE) * 1024 * 1024 * 1024;
      console.log("check_size::", check_size);
      if (file.type == "audio/wav" || file.type == "audio/mp3") {
        return false;
      } else {
        const resolution: any = await funcCheckResolution(file, isVideo);
        console.log("isVideo if", isVideo);
        if (!isVideo || !!check_size || !!resolution.size) {
          console.log("isVideofalse::", "");
          isVideo == false
            ? message.error("You can only upload mp4 avi mov webm or flv file!")
            : !!check_size
            ? message.error(`${file.name} file size exceeds maximum limit.`)
            : message.error(
                "You can only upload videos with a resolution of 360p and above or not exceeding 2160p."
              );
          setVideoFile(null);
          return true;
        } else {
          console.log("isVideo283::", isVideo);
          // console.log("check_size",check_size)
          return false;
        }
      }
    },
  };

  const funcCheckResolution = async (file: any, isVideo: boolean) => {
    try {
      //check resolution
      if (isVideo) {
        const url_video = URL.createObjectURL(file);
        const resolution = new Promise((resolve) => {
          var video: any = document.createElement("video");
          video.src = url_video;
          video.addEventListener("loadedmetadata", (event: any) => {
            const time = new Date(video.duration * 1000)
              .toISOString()
              .slice(11, 19);
            const height = video.videoHeight;
            const width = video.videoWidth;
            if (
              (360 > video.videoHeight && 480 > video.videoWidth) ||
              (1440 < video.videoHeight && 2560 < video.videoWidth)
            ) {
              // console.log("<360p or >2k")
              // resolve({ height, width,size:true});
              if (
                (480 > video.videoHeight && 360 > video.videoWidth) ||
                (2560 < video.videoHeight && 1440 < video.videoWidth)
              ) {
                console.log("<360p or >2k");
                resolve({ height, width, size: true });
              }
              resolve({ height, width, size: false });
            }
            resolve({ height, width, size: false });
          });
        });
        return resolution;
      } else {
        return { size: true };
      }
    } catch (e) {
      console.error(e);
    }
  };

  // // new upload onFinish

  const [file, setFile] = useState<any>(null);
  const [dataFile, setDataFile] = useState<any>(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState<any>(null);
  const chunkSize = 1024 * 1024 * 50;

  const handleFinish = (value: any) => {
    console.log("value:", value);
    setFile(value.dragger.file);
    setDataFile(value);
    props.setProgressUpload(1);
    setCurrentChunkIndex(0);
  };

  useEffect(() => {
    const uploadChunk = async (readerEvent: any) => {
      try {
        if (!file) return;
        const data = readerEvent.target.result;

        // Set up the parameters for the request
        const params = new URLSearchParams();
        params.set("title", file.name);
        params.set("currentChunkIndex", currentChunkIndex);
        const value: any = Math.ceil(file.size / chunkSize);
        params.set("totalChunks", value);

        params.set("scenario", JSON.stringify({ data: dataFile.scenario }));
        params.set("tag", dataFile.tag);
        params.set("name", dataFile.name);

        // Set up the headers for the request
        const headers = {
          "Content-Type": "application/octet-stream",
          "x-access-token": getToken(),
        };
        const url =
          process.env.REACT_APP_BACKEND + `/task/create?` + params.toString();

        // Make the POST request to the server

        await fetch(url, {
          method: "POST",
          headers: headers,
          body: data,
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("res::", res);
            // Check if this is the last chunk
            const isLastChunk =
              currentChunkIndex === Math.ceil(file.size / chunkSize) - 1;
            // If it is, set the final filename and reset the current chunk index
            if (isLastChunk) {
              file.finalFilename = res.finalFilename;
              props.setProgressStatus("success");
              setCurrentChunkIndex(null);
            } else {
              // If it's not, increment the current chunk index
              setCurrentChunkIndex(currentChunkIndex + 1);
            }

            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (res.message == "success" || res.message == "Chunk uploaded") {
              if (res.message == "success") {
                //create notification
                const res_noti = await sendNotification({
                  content: `${user.user_name} ได้อัปโหลด ${value.name}`,
                  type: "create",
                });
                props.handleCancel();
                props.openNotification("success", "Upload video success", "");

                props.setProgressUpload(0);
                setFile(null);
                setFiles([]);
                setDataFile(null);
                props.setProgressStatus("active");
                props.setIsModalOpen(false);
                props.form.resetFields();
              }
            } else {
              message.error(`file upload failed.`);
              props.setProgressStatus("exception");
            }
          })
          .catch((e) => {
            console.error(e);
            props.setProgressStatus("exception");
            message.error(`create task failed please try again.`);
          });
      } catch (e) {
        message.error(`file upload failed.`);
        console.error("e");
      }
    };

    const readAndUploadCurrentChunk = () => {
      try {
        console.log("readAndUploadCurrentChunk func");
        if (!file) return;
        // Calculate the start and end of the current chunk
        console.log("videoFile readAndUploadCurrentChunk::", file);
        const from = currentChunkIndex * chunkSize;
        const to =
          (currentChunkIndex + 1) * chunkSize >= file.size
            ? file.size
            : from + chunkSize;

        // Slice the file to get the current chunk
        const blob = file.slice(from, to);
        // const blob = videoFile.slice(from, to);

        const reader = new FileReader();
        // Set the onload function to upload the chunk when it's read
        reader.onload = (e) => uploadChunk(e);
        // Read the blob as a data URL
        reader.readAsDataURL(blob);
      } catch (e) {
        console.error(e);
      }
    };
    // If a chunk index is set, read and upload the current chunk
    if (currentChunkIndex != null) readAndUploadCurrentChunk();
  }, [chunkSize, currentChunkIndex, file]);

  const chunks = Math.ceil(file?.size / chunkSize);

  return (
    <Modal
      title={
        <Row justify={"space-between"} align={"middle"}>
          <Col style={{ fontSize: "16pt" }}>
            {translate("Upload Your File")}
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (props.progressUpload != 0) {
                props.setIsModalOpen(false);
                // props.setHindUpload(true);
              }
              props.setIsModalOpen(false);
            }}
          >
            <SwitcherOutlined style={{ fontSize: "14pt" }} />
          </Col>
        </Row>
      }
      open={props.isModalOpen}
      width={700}
      style={{ borderRadius: "5px" }}
      okText={translate("Done")}
      okType={"primary"}
      onCancel={() => {
        props.setProgressUpload(0);
        props.setProgressStatus("active");
        // setProgressUpload(0)
        // setProgressStatus("active")
        props.handleCancel();
      }}
      closable={false}
      onOk={() => props.form.submit()}
      footer={
        <Row justify={"space-between"} align={"middle"}>
          <Col xs={12}>
            {props.progressUpload != 0 && (
              <Progress
                // percent={props.progressUpload}
                percent={Math.round((currentChunkIndex / chunks) * 100)}
                size="small"
                status={props.processStatus}
              />
            )}
            {/* {progressUpload != 0 && (
              <Progress
                percent={progressUpload}
                size="small"
                status={processStatus}
              />
            )} */}
          </Col>
          <Col>
            <Row gutter={8}>
              <Col xs={12}>
                <Button
                  style={{ width: "100%" }}
                  type="default"
                  onClick={() => props.handleCancel()}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => {
                    props.setProgressStatus("active");
                    // setProgressStatus("active");
                    props.form.submit();
                  }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      // confirmLoading={loading}
    >
      {contextHolder}
      <Form
        name="nest-messages"
        style={{ maxWidth: 700 }}
        validateMessages={props.validateMessages}
        onFinish={handleFinish}
        form={props.form}
      >
        <Form.Item
          name={"name"}
          label={translate("Title")}
          rules={[{ required: true }]}
        >
          <Input placeholder={translate("Title")} />
        </Form.Item>
        <Form.Item name={"tag"} label={translate("Tag")}>
          <Select
            onClick={() => setOpen(true)}
            ref={selectRef}
            mode="multiple"
            onSelect={(item) => {
              console.log("props.selectTag:", props.selectTag);
              props.setSelectTag([...props.selectTag, item]);
            }}
            style={{ width: "100%" }}
            autoFocus={true}
            onDropdownVisibleChange={handleSelectOpen}
            value={props.selectTag}
            open={open}
            placeholder={translate("Tag")}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input placeholder="Please enter tag" ref={inputRef} />
                  <ColorPicker
                    format={formatHex}
                    value={color}
                    onChange={onColorChange}
                    onFormatChange={setFormatHex}
                    placement="topRight"
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item, index) => ({
              key: index,
              label: item.name,
              value: item.value,
            }))}
            tagRender={tagRender}
          />
        </Form.Item>
        <Form.Item
          name="dragger"
          rules={[
            {
              required: true,
              // , message: translate("input-file-warning")
            },
          ]}
        >
          <Dragger {...propsDragger}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "#7D44F3" }} />
            </p>
            <p className="ant-upload-text" style={{ fontWeight: "bold" }}>
              {translate("Click or drag file to this area to upload")}
            </p>
            <p className="ant-upload-hint">
              {translate("Support for a single file Upload")}
            </p>
            <p className="ant-upload-hint" style={{ color: "#2b87f7" }}>
              {`Only MP4, AVI, MOV, WEBM, FLV, MP3, WAV Files with max size of 3Gb`}
            </p>
            <p>
              <Button
                icon={<UploadOutlined />}
                style={{
                  marginTop: "20px",
                  color: "white",
                  background: "linear-gradient(0.25turn,#CE5BEB, #1E88E5)",
                  borderRadius: "5px",
                  textDecoration: "none",
                  fontSize: "10px",
                }}
              >
                {translate("Click to Upload")}
              </Button>
            </p>
          </Dragger>
        </Form.Item>
        <p>Scenario</p>
        <Form.Item name="scenario" rules={[{ required: true }]}>
          <Select
            mode="multiple"
            placeholder="Please select scenario"
            onChange={handleChange}
            style={{ width: "100%" }}
            options={props.scenarioShow}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) => {
              let result: boolean;
              if (option?.label) {
                result =
                  (option?.label)
                    .toLocaleString()
                    .toLowerCase()
                    .indexOf(input.toLocaleString().toLowerCase()) >= 0;
              } else {
                result = false;
              }
              return result;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadVideo;
